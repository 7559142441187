export * from "./errorCodes";

export interface PagedResponse<T> {
  totalCount: number;
  items: T[];
}

export type LinearRing = {
  latitude: number;
  longitude: number;
}[];

export interface Address {
  zipCode?: string;
  city?: string;
  street?: string;
}

export interface GeolocationResponse {
  longitude: number;
  latitude: number;
}

export enum ForwardToPlatform {
  NONE = 0,
  UDP = 1,
  MOVEMENT_MAIL_ALERTING = 2,
}

/* 
  This new enum breaks naming convention with Camel Case as opposed to Pascal Case, 
  this is done for ease of mapping when reconciling the column (property) being sorted.
  When getting the column name from the BeaconTable, the column name is in Camel Case, 
  so it is easier to map the enum to the column name.
*/
export enum BeaconSortingColumn {
  id = 0,
  serial = 1,
  onOffSwitch = 2,
  batteryVoltage = 3,
  constructionProjectName = 4,
  iccid = 5,
  activeWarnings = 6,
  lastMessageReceivedAt = 7,
  constructionSiteName = 8,
  organizationName = 9,
}

export enum BeaconSortingOrder {
  Ascending = 0,
  Descending = 1,
}

export interface BeaconTableSorting {
  sortColumnId: BeaconSortingColumn | undefined;
  sortOrderId: BeaconSortingOrder | undefined;
}

export enum ErrorSeverity {
  Warning = 0,
  Error = 1,
}

export interface ConstructionProject {
  id: string;
  name: string;
  isArchived: boolean;
  createdAutomatically: boolean;
  address?: Address;
  area?: LinearRing;
  forwardToPlatform?: ForwardToPlatform;
  organizationName?: string;
  organizationId?: string;
}

export type ConstructionProjectMutableProperties = Pick<
  ConstructionProject,
  "name" | "area" | "address" | "forwardToPlatform"
> &
  Partial<Pick<ConstructionProject, "isArchived">>;

export interface ConstructionSite {
  id: string;
  name: string;
  createdAt: Date;
  polygon?: LinearRing;
}

export type ConstructionSiteMutableProperties = Pick<ConstructionSite, "name">;

export interface PostConstructionSiteRequest {
  name?: string;
  beaconIds: string[];
}

export interface ConstructionSiteWithStatus extends ConstructionSite {
  numberOfBeacons: number;
  hasActiveError: boolean;
}

export interface BeaconOnOffSwitch {
  isOn: boolean;
  lastReportedAt: Date;
}

export interface BeaconActiveError {
  startedAt: Date;
  code: string;
  severity: ErrorSeverity;
  hasBeenRead: boolean;
  hasBeenReadAt?: Date;
}

export interface Beacon {
  id: string;
  serial: string;
  iccid: string;
  isDisabled: boolean;
  constructionProjectId?: string;
  constructionProjectName?: string;
  constructionProjectCreatedAutomatically?: boolean;
  constructionSiteId?: string;
  constructionSiteName?: string;
  organizationName?: string;
  organizationId?: string;
  lastMessageReceivedAt?: Date;
  environment?: BeaconEnvironment;
  onOffSwitch?: BeaconOnOffSwitch;
  activeErrors?: BeaconActiveError[];
  fwv?: string;
  hwv?: string;
}

export interface BeaconWithPosition extends Beacon {
  position: BeaconPosition;
}

export interface FindBeaconInAreaRequest {
  area: LinearRing;
}

export interface PutBeaconBySolutionOperatorRequest {
  isDisabled: boolean;
}

export interface Organization {
  id: string;
  name: string;
  forwardToPlatform?: ForwardToPlatform;
}

export type OrganizationMutableProperties = Pick<
  Organization,
  "name" | "forwardToPlatform"
>;

export interface User {
  id: string;
  email: string;
}

export interface LatestBeaconPosition {
  beaconId: string;
  position: {
    measuredAt: Date;
    value: {
      latitude: number;
      longitude: number;
      altitude: number;
    };
  };
}

export enum StateOfPosition {
  GPSOnly = 1,
  CorrectedButInaccurate = 2,
  CorrectedAndAccurate = 3,
}

export enum Trigger {
  TIME = 1,
  MOVE = 2,
  BATTERY_LOW = 4,
  POWER_ON = 8,
  POWER_OFF = 16,
}

export function isOn(trigger: Trigger) {
  return (trigger & Trigger.POWER_ON) === Trigger.POWER_ON && !isOff(trigger);
}

export function isOff(trigger: Trigger) {
  return (trigger & Trigger.POWER_OFF) === Trigger.POWER_OFF;
}

export interface Measurement {
  measuredAt: Date;
  reason: Trigger;
}

export interface BeaconPosition extends Measurement {
  value: {
    pos: {
      lat: number;
      lon: number;
      alt: number;
    };
    md: {
      hAcc: number;
      vAcc: number;
      tAcc: number;
      hDop: number;
      pDop: number;
      vDop: number;
      satU: number;
      pst: number;
      cprs: number;
    };
  };
}


export interface BeaconEnvironment extends Measurement {
  value: {
    batt: {
      v: number;
    };
    cell: {
      iccid: string;
    };
    remainingRuntimeHours: number;
    remainingRuntimeRatio: number;
  };
}

export interface BeaconError extends Measurement {
  value: {
    code: string;
    data?: any;
    active: boolean;
    severity: ErrorSeverity;
  };
}

export interface BeaconLifecycle extends Measurement {
  value: Trigger;
}

export interface BeaconActiveErrorResponse {
  startedAt: Date;
  code: string;
  beaconId: string;
  severity: ErrorSeverity;
  hasBeenRead: boolean;
  hasBeenReadAt?: Date;
}

export type CorrectionServiceType = "NTRIP" | "PointPerfect";

export interface PutBeaconConfigurationRequest {
  activityIntervalPeriod: number;
  motionDetectionThreshold: number;
  correctionServiceType: CorrectionServiceType;
  correctionServiceConfiguration: {
    endpoint: string;
    port: number;
  };
  ntripConfiguration?: {
    mountpoint: string;
    username: string;
    password: string;
    ntripRequestInterval: number;
  };
  pointPerfectConfiguration?: {
    correctionTopic: string;
    distributionTopic: string;
    clientId: string;
  };
}

export type GetBeaconConfigurationResponse = PutBeaconConfigurationRequest;

export interface GetBeaconConfigurationStateResponse {
  desired?: GetBeaconConfigurationResponse;
  reported?: GetBeaconConfigurationResponse;
}


export type MarkerColor = "blue" | "green" | "red" | "orange" | "grey";